import React from 'react'
import './Footer.css'

export default () => (
  <div>
    <footer className="footer">
      <div className="main-dual container">
        <div className="main-dual-column">Thanks for reading.</div>
        <div className="main-dual-column">
          <div className="footer--Category">LINKS</div>
          <div>
            <a
              href="https://twitter.com/danielaction"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </div>
          <a
            href="https://instagram.com/danielaction"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </div>
      </div>
      <div className="container taCenter">
        <div className="section">
          <span>
            © Daniel Kim, {new Date().getFullYear()} | All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  </div>
)
